import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import { Modal } from "react-bulma-components";
import InviteeForm from "../InviteeForm/InviteeForm";
import { Button } from "react-bulma-components";
import { Card } from "react-bulma-components";
import {
  Notification
} from "react-bulma-components";
//actionCreators
import {
  addInvitee,
  clearUpdatableInvitee,
  updateInvitee,
} from "../../store/actionCreators/invitee";
import { closeModal } from "../../store/actionCreators/modal";

//styles
import styles from "./InviteeModal.module.css";

const { Footer } = Card;

export default function AddInviteeModal() {
  const dispatch = useDispatch();
  const { currentlyOpenModal } = useSelector((state) => state.modal);
  const { updatableInvitee } = useSelector((state) => state.invitee);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    status: "confirmed",
    additionalGuests: [], // Initialize with one guest
    createdAt: ""
  });

  // State for additional guests
  let [additionalGuests, setAdditionalGuests] = useState([]);

  useEffect(() => {
    if (updatableInvitee) {
      setFormData({
        ...updatableInvitee,
      });
      // If there are additional guests in the updatableInvitee, set them as well
      if (updatableInvitee.additionalGuests) {
        setAdditionalGuests(updatableInvitee.additionalGuests);
      }
    }
  }, [updatableInvitee]);

  function onFormValueChange(clickEvent) {
    const { name, value } = clickEvent.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function resetState() {
    setFormData({
      name: "",
      email: "",
      status: "confirmed",
    });
    setAdditionalGuests([]); // Reset additional guests
  }

  function onFormSubmit(e) {
    e.preventDefault();

    // Basic validation for required fields
    if (!formData.name || !formData.email) {
      setError("Name and email are required!");
      return;
    }

    const emptyNameGuest = additionalGuests.find((guest) => !guest.name.trim());

    if (emptyNameGuest) {
      setError("One or more guest names are empty.");
      return false; // Returning false if there's an error
    }

    formData.createdAt = new Date();

    const inviteeData = {
      ...formData,
      additionalGuests, // Include additional guests in the submission
    };

    if (updatableInvitee) {
      return dispatch(updateInvitee(inviteeData)).then(resetState);
    }
    dispatch(addInvitee(inviteeData)).then(resetState);
  }

  function onModalClose() {
    if (updatableInvitee) {
      return dispatch(clearUpdatableInvitee());
    }
    dispatch(closeModal());
  }

  return (
    <Modal
      show={currentlyOpenModal === "invitee" || updatableInvitee !== null}
      onClose={onModalClose}
      closeOnEsc={true}
      showClose={false}
    >
      <Modal.Card className={styles.eventModal}>
        <Modal.Card.Header>
          <Modal.Card.Title style={{ fontSize: "20px", fontWeight: "bold"}}>{updatableInvitee ? "Update Guest" : "Add Guest"}</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Content className={styles.inviteeModalContent}>
          <form onSubmit={onFormSubmit}>
            {error && <Notification color="danger">{error}</Notification>}
            <InviteeForm
              formData={formData}
              additionalGuests={additionalGuests} // Pass the additional guests
              onFormValueChange={onFormValueChange}
              setAdditionalGuests={setAdditionalGuests} // Pass the function to update additional guests
            />

            <Footer className={styles.footer}>
              <Button
                className={styles.submitButton}
                type="submit" // Ensure the button submits the form
              >
                {updatableInvitee ? "Update" : "Submit"}
              </Button>
            </Footer>
          </form>
        </Modal.Content>
      </Modal.Card>
    </Modal>
  );
}
