import { db } from "../../auth/firebase";

import { collection, doc, deleteDoc, updateDoc, addDoc } from "firebase/firestore";

import {
  SET_UPDATABLE_INVITEE,
  CLEAR_UPDATABLE_INVITEE,
  EDIT_UPDATABLE_INVITEE,
} from "../actionTypes/invitee";

import { closeModal } from "./modal";
import {
  displaySuccessNotification,
  displayErrorNotification,
} from "./notification";

function onSetUpdatableInvitee(invitee) {
  return {
    type: SET_UPDATABLE_INVITEE,
    payload: {
      invitee,
    },
  };
}

function setUpdatableInvitee(inviteeId) {
  return (dispatch, getState) => {
    const { selectedEvent } = getState().eventInfo;
    const { invitees } = selectedEvent;
    const updatableInvitee = invitees.find(({ _id }) => _id === inviteeId);

    dispatch(onSetUpdatableInvitee(updatableInvitee));
  };
}

function clearUpdatableInvitee() {
  return {
    type: CLEAR_UPDATABLE_INVITEE,
  };
}

function editUpdatableInvitee(inviteeInfo) {
  return {
    type: EDIT_UPDATABLE_INVITEE,
    payload: {
      inviteeInfo,
    },
  };
}

// function updateInvitee(invitee) {
//   return (dispatch, getState) => {
//     const { selectedEvent } = getState().eventInfo;
//     const { _id } = selectedEvent;

//     return new Promise(function (resolve, reject) {
//       db.collection("events")
//         .doc(_id)
//         .collection("invitees")
//         .doc(invitee._id)
//         .update({
//           ...invitee,
//         })
//         .then(function () {
//           resolve("updated");
//           dispatch(clearUpdatableInvitee());
//           dispatch(displaySuccessNotification("Invitee Successfully updated!"));
//         })
//         .catch(function ({ message }) {
//           dispatch(displayErrorNotification(message));
//         });
//     });
//   };
// }

function updateInvitee(invitee) {
  return (dispatch, getState) => {
    const { selectedEvent } = getState().eventInfo;
    const { _id } = selectedEvent;

    return new Promise(async (resolve, reject) => {
      try {
        // Correctly reference the invitee document
        const inviteeRef = doc(db, "events", _id, "invitees", invitee._id);

        await updateDoc(inviteeRef, {
          ...invitee,
        });

        resolve("updated");
        dispatch(clearUpdatableInvitee());
        dispatch(displaySuccessNotification("Invitee Successfully updated!"));
      } catch (error) {
        const { message } = error;
        dispatch(displayErrorNotification(message));
        reject(message);
      }
    });
  };
}

// function addInvitee(invitee) {
//   return (dispatch, getState) => {
//     const { selectedEvent } = getState().eventInfo;
//     const { _id } = selectedEvent;

//     return new Promise(function (resolve, reject) {
//       db.collection("events")
//         .doc(_id)
//         .collection("invitees")
//         .add(invitee)
//         .then(function (docRef) {
//           dispatch(closeModal());
//           resolve(docRef.id);
//           dispatch(displaySuccessNotification("Invitee Successfully added!"));
//         })
//         .catch(function ({ message }) {
//           dispatch(displayErrorNotification(message));
//         });
//     });
//   };
// }

function addInvitee(invitee) {
  return (dispatch, getState) => {
    const { selectedEvent } = getState().eventInfo;
    const { _id } = selectedEvent;

    return new Promise(async (resolve, reject) => {
      try {
        // Reference the invitees collection for the selected event
        const inviteesRef = collection(doc(db, "events", _id), "invitees");

        // Add the new invitee document
        const docRef = await addDoc(inviteesRef, invitee);

        // Dispatch actions for success
        dispatch(closeModal());
        dispatch(displaySuccessNotification("Guest successfully added!"));

        // Resolve the promise with the document ID
        resolve(docRef.id);
      } catch (error) {
        // Handle errors
        const { message } = error;
        dispatch(displayErrorNotification(message));
        reject(message);
      }
    });
  };
}

// function deleteInvitee(inviteeId) {
//   return (dispatch, getState) => {
//     const { selectedEvent } = getState().eventInfo;

//     db.collection("events")
//       .doc(selectedEvent._id)
//       .collection("invitees")
//       .doc(inviteeId)
//       .delete()
//       .then(function () {
//         dispatch(displaySuccessNotification("Invitee Successfully deleted!"));
//       })
//       .catch(function ({ message }) {
//         dispatch(displayErrorNotification(message));
//       });
//   };
// }

function deleteInvitee(inviteeId) {
  return (dispatch, getState) => {
    const { selectedEvent } = getState().eventInfo;

    // Check if selectedEvent and its _id are available
    if (!selectedEvent || !selectedEvent._id) {
      dispatch(displayErrorNotification("No selected event found."));
      return;
    }

    // Construct the document reference for the invitee
    const inviteeDocRef = doc(db, "events", selectedEvent._id, "invitees", inviteeId);

    deleteDoc(inviteeDocRef)
      .then(() => {
        dispatch(displaySuccessNotification("Guest successfully removed!"));
      })
      .catch((error) => {
        console.error("Error deleting invitee:", error); // Debugging log
        dispatch(displayErrorNotification(error.message));
      });
  };
}

export {
  setUpdatableInvitee,
  deleteInvitee,
  addInvitee,
  clearUpdatableInvitee,
  editUpdatableInvitee,
  updateInvitee,
};
