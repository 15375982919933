import React from "react";
//import SearchForm from "../SearchForm/SearchForm";
import { Hero } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Container } from "react-bulma-components";
import classNames from "./Header.module.css";

const { Body } = Hero;

function Header(props) {
  return (
    <header className={classNames.header}>
      <Section>
        <Hero>
          <Body>
            <Container>
              <Heading
                size={1}
                className={`${classNames.title} has-text-centered has-text-white`}
              >
                RSVPs at Your Fingertips
              </Heading>
              <Heading
                subtitle
                size={3}
                className={`${classNames.subTitle} has-text-centered has-text-white`}
              >
                Easily create events and receive swift responses!
              </Heading>
            </Container>
          </Body>
        </Hero>
      </Section>
    </header>
  );
}

export default Header;
