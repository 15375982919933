import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import { Modal } from "react-bulma-components";
import EventForm from "../EventForm/EventForm";
import { Notification } from "react-bulma-components";
//actionCreators
import { addEvent, updateEvent } from "../../store/actionCreators/event";
import { closeModal } from "../../store/actionCreators/modal";

import { clearUpdatableEvent } from "./../../store/actionCreators/eventAction"

// file upload
import compressAndUploadImage from "../Utility/fileUpload";

//hooks
import useUpdatableEvent from "../../hooks/useUpdatableEvent";

//styles
import styles from "./EventModal.module.css";

export default function AddEventModal() {
  const dispatch = useDispatch();
  const { currentlyOpenModal } = useSelector((state) => state.modal);
  const { updatableEvent } = useSelector((state) => state.eventAction);
  const [error, setError] = useState(null);
  const [isSubmitActive, setSubmitActive] = useState(true);
  const isAnyEventUpdatable = useUpdatableEvent();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: "",
    startTime: "",
    endTime: "",
    timezone: "",
    venue: "",
    photo: "",
    active: true,
    showGuestList: true,
    allowGuestNote: true,
    createdAt: ""
  });

  async function onFormValueChange(clickEvent) {
    const { name, value, type, checked, files } = clickEvent.target;

    if (type == "file") {
      setSubmitActive(false);
      const downloadURL = await compressAndUploadImage(
        files[0],
        "compressed-uploads",
        {
          maxSizeMB: 0.25, // Compress to a maximum size of 250kb
          maxWidthOrHeight: 1024, // Resize if width or height exceeds 4000 pixels
        }
      );
      formData.uploadedPhoto = downloadURL;
      setSubmitActive(true);
    }

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value, // Update checkbox value based on its checked state
    });
  }

  function resetForm() {
    setFormData({
      title: "",
      description: "",
      date: "",
      startTime: "",
      endTime: "",
      timezone: "",
      venue: "",
      photo: "",
      active: true,
      showGuestList: true,
      allowGuestNote: true,
    });
  }

  function onFormSubmit() {
    // Basic validation for required fields
    if (!formData.title) {
      setError("Please add event title");
      return;
    }
    if (!formData.description) {
      setError("Please add event description");
      return;
    }
    if (!formData.date) {
      setError("Please add event date");
      return;
    }
    if (!formData.startTime) {
      setError("Please add start time");
      return;
    }
    if (!formData.endTime) {
      setError("Please add end time");
      return;
    }
    if (!formData.timezone) {
      setError("Please add timezone");
      return;
    }
    if (!formData.venue) {
      setError("Please add event venue");
      return;
    }
    if (!formData.photo) {
      setError("Please add photo");
      return;
    }

    formData.createdAt = new Date();

    dispatch(addEvent(formData)).then(resetForm);
  }

  function onModalClose() {
    if (updatableEvent) {
      resetForm();
      return dispatch(clearUpdatableEvent());
    }
    resetForm();
    dispatch(closeModal());
  }

  function onEventUpdate() {
    // Basic validation for required fields
    if (!formData.title) {
      setError("Please add event title");
      return;
    }
    if (!formData.description) {
      setError("Please add event description");
      return;
    }
    if (!formData.date) {
      setError("Please add event date");
      return;
    }
    if (!formData.startTime) {
      setError("Please add start time");
      return;
    }
    if (!formData.endTime) {
      setError("Please add end time");
      return;
    }
    if (!formData.timezone) {
      setError("Please add timezone");
      return;
    }
    if (!formData.venue) {
      setError("Please add event venue");
      return;
    }
    if (!formData.photo) {
      setError("Please add photo");
      return;
    }

    formData.updatedAt = new Date();
    
    dispatch(updateEvent(formData)).then(resetForm);
  }

  useEffect(() => {
    if (updatableEvent) {
      setFormData({ ...updatableEvent });
    }
  }, [updatableEvent]);

  return (
    <Modal
      show={currentlyOpenModal === "event" || isAnyEventUpdatable}
      onClose={onModalClose}
      closeOnEsc={true}
      showClose={false}
    >
      <Modal.Card className={styles.eventModal}>
        <Modal.Card.Header>
          <Modal.Card.Title style={{ fontSize: "20px", fontWeight: "bold" }}>
            {isAnyEventUpdatable ? "Update Event" : "Add An Event"}
          </Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Content className={styles.eventModalContent}>
          <EventForm
            formData={formData}
            onFormValueChange={onFormValueChange}
          />
        </Modal.Content>
        <Modal.Card.Footer className={styles.footer}>
          {error && (
            <div>
              <Notification
                style={{ marginBottom: "10px", textAlign: "center" }}
                color="warning"
              >
                {error}
              </Notification>
            </div>
          )}
          {isAnyEventUpdatable ? (
            <button
              className="button is-info is-fullwidth"
              onClick={onEventUpdate}
              disabled={isSubmitActive ? false : true}
            >
              {isSubmitActive ? "Update Event" : "Uploading image"}
            </button>
          ) : (
            <button
              className={`button is-success is-fullwidth ${styles.submitButton}`}
              onClick={onFormSubmit}
              disabled={isSubmitActive ? false : true}
            >
              {isSubmitActive ? "Submit" : "Uploading image"}
            </button>
          )}
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
}
