import React from "react";
import { Redirect } from "react-router-dom";

import { Columns } from "react-bulma-components";
import schedule from "../../assets/images/schedule.svg";
import AddEventButton from "../AddEventButton/AddEventButton";

//hooks

import useHasEvents from "../../hooks/useHasEvents.js";

import classNames from "./EventIntro.module.css";

const { Column } = Columns;

export default function EventIntro() {
  const hasEvents = useHasEvents();

  return hasEvents ? (
    <Redirect to="/dashboard" />
  ) : (
    <Column>
      <h2 className={`title is-5 has-text-centered ${classNames.title}`}>
        No events found!
      </h2>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
      >
        <AddEventButton className={classNames.addEventBtn} />
      </div>
      <figure className={`image ${classNames.image}`}>
        <img src={schedule} alt="Calendar" />
      </figure>
    </Column>
  );
}
