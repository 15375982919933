// Import necessary Firebase modules
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore"; // Firestore functions
import app from "./firebase"; // Import your firebase app instance

const db = getFirestore(app); // Initialize Firestore
const auth = getAuth(app); // Initialize Firebase Auth

// Refactor signUpUser function for modular Firebase SDK
export default async function signUpUser(type) {
  let provider = null;

  // Set provider based on the type (e.g., Google)
  switch (type) {
    case "google":
      provider = new GoogleAuthProvider(); // Initialize GoogleAuthProvider
      break;
    default:
      return; // No provider, exit function
  }

  try {
    // Sign in with Popup using the selected provider
    const result = await signInWithPopup(auth, provider);

    // Destructure result to get additional user info and user data
    const { user, _tokenResponse } = result;
    const { isNewUser } = _tokenResponse;
    const { uid, email, displayName } = user;

    if (isNewUser) {
      // Add new user document to Firestore collection "users"
      await setDoc(doc(db, "users", uid), {
        uid,
        email,
        displayName
      });

    }
  } catch (error) {
    // Handle errors here
    console.error("Error during sign-up: ", error.code, error.message);
  }
}
