import React from "react";
import { Button, Section, Container, Heading, Box, Columns } from "react-bulma-components";
import { Link } from "react-router-dom";

const InvalidEventPage = () => {
  return (
    <Section>
      <Container>
        <Columns centered>
          <Columns.Column size={6}>
            <Box>
              <Heading size={3} className="has-text-centered">
                Event not found
              </Heading>
              <p className="has-text-centered">
                Oops! The event you're trying to access doesn't exist or not active.
              </p>
              <div className="has-text-centered mt-5">
                <Button color="primary" renderAs={Link} to="/">
                  Go Back to Home
                </Button>
              </div>
            </Box>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  );
};

export default InvalidEventPage;
