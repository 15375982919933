import React from "react";
import { useSelector } from "react-redux";
import { exportToPDF, exportToCSV } from "../Utility/guestUtils";

export default function AddExportButton({ className, ...props }) {
  const { selectedEvent } = useSelector((state) => state.eventInfo);

  function downloadDataInPdf() {
    exportToPDF(selectedEvent);
  }

  function downloadDataInCsv() {
    exportToCSV(selectedEvent);
  }

  return (
    <div>
      <div className={`dropdown is-right is-hoverable`}>
        <div className="dropdown-trigger">
          <button
            class="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
          >
            <span class="icon">
              <i class="fa-solid fa-download"></i>
            </span>
            <span>Export Data</span>
            <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <button
              className="button is-white dropdown-item"
              onClick={downloadDataInCsv}
            >
              <span>Excel format</span>
            </button>
            <button
              className="button is-white dropdown-item"
              onClick={downloadDataInPdf}
            >
              <span>PDF format</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
