import React from "react";
import { Link } from "react-router-dom";
import classNames from "./Navbar.module.css";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../Logo/Logo";
import { Navbar } from "react-bulma-components";

import {
  openDropdown,
  closeDropdown,
} from "../../store/actionCreators/dropdown";

export default function NavbarComp() {
  const dispatch = useDispatch();
  const { isDropdownOpen } = useSelector((state) => state.dropDown);

  return (
    <Navbar color="light">
      <Navbar.Brand>
        <Link className="navbar-item" to="/">
          <Logo />
        </Link>
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Container align="right">
          <div className="navbar-item">
            <Link to="/signin" style={{color: "#363636", marginRight: "8px"}} arrowless={true} className={classNames.isMobileOnly}>
              Dashboard
            </Link>
          </div>
        </Navbar.Container>
      </Navbar.Menu>
      {/* <Navbar.Menu>
        <Navbar.Container position="end">
          {user ? (
            <>
              <Navbar.Item>
                <Navbar.Link to="/dashboard">Dashboard</Navbar.Link>
              </Navbar.Item>
              <Navbar.Dropdown>
                <Navbar.Item>
                  <div
                    onClick={toggleDropdown}
                    className={`${classNames.imgBtn} ${
                      isDropdownOpen ? classNames.border : ""
                    }`}
                    style={{
                      backgroundImage: `url(${user.photoURL})`,
                    }}
                  ></div>
                </Navbar.Item>
              </Navbar.Dropdown>
            </>
          ) : (
            <div className="navbar-item">
              <Navbar.Link to="/signin" className="button is-dark">
                Create event
              </Navbar.Link>
            </div>
          )}
        </Navbar.Container>
      </Navbar.Menu> */}
    </Navbar>
  );
}
