import React from "react";

import { Columns } from "react-bulma-components";
import { Card } from "react-bulma-components";

import party from "../../assets/images/party.svg";

import classNames from "./InviteeIntro.module.css";

const { Column } = Columns;
const { Content } = Card;

export default function InviteeIntro() {
  return (
    <Column>
      <Content>
        <h2 className={`title is-5 has-text-centered ${classNames.title}`}>
          No guests! Start inviting.
        </h2>
        <figure className={`image ${classNames.image}`}>
          <img src={party} alt='Calendar' />
        </figure>
        {/* <AddEInviteeButton className={classNames.addInviteeBtn} /> */}
      </Content>
    </Column>
  );
}
