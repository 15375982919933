import imageCompression from 'browser-image-compression';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import heic2any from 'heic2any';

/**
 * Compresses an image and uploads it to Firebase Storage, then returns the download URL.
 * @param {File} file - The image file to be compressed and uploaded.
 * @param {string} folder - The folder path in Firebase Storage where the file will be stored.
 * @param {Object} compressionOptions - Options for image compression.
 * @returns {Promise<string>} - A promise that resolves with the download URL of the uploaded file.
 */
async function compressAndUploadImage(file, folder = "images", compressionOptions = {
  maxSizeMB: 1, // Maximum size in MB
  maxWidthOrHeight: 1920, // Max width or height to maintain aspect ratio
  useWebWorker: true, // Use web worker for compression
}) {
  if (!file) {
    throw new Error("No file provided for upload.");
  }

  try {
    let imageFile = file;

    // Convert HEIC to JPEG if the file type is HEIC
    if (file.type === 'image/heic' || file.type === 'image/heif') {
      const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
      imageFile = new File([convertedBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), { type: "image/jpeg" });
    }

    // Compress the image
    const compressedFile = await imageCompression(imageFile, compressionOptions);

    const storage = getStorage();
    const filePath = `${folder}/${compressedFile.name}`;
    const storageRef = ref(storage, filePath);

    // Upload the compressed file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, compressedFile);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (err) {
    console.error("Error compressing or uploading image:", err);
    throw new Error("Failed to compress or upload image.");
  }
}

export default compressAndUploadImage;
