import React from "react";

import { useDispatch } from "react-redux";
import { openInviteeModal } from "../../store/actionCreators/modal";

export default function AddInviteeButton({ className, ...props }) {
  const dispatch = useDispatch();

  function openModal() {
    dispatch(openInviteeModal());
  }

  return (
    <button
      className={`button ${className} ${
        props.isMobile && "is-white dropdown-item"
      }`}
      onClick={openModal}
      {...props}
    >
      <span class="icon">
        <i class="fa-solid fa-plus"></i>
      </span>
      <span>Add a Guest</span>
    </button>
  );
}
