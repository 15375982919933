import React from "react";

import { Card } from "react-bulma-components";
import { Columns } from "react-bulma-components";

import calendar from "../../assets/images/calendar_pick.svg";
import classNames from "./EventInfoIntro.module.scss";

const { Column } = Columns;
const { Content } = Card;

export default function EventInfo() {
  return (
    <Column>
      <Card className={classNames.eventInfoIntro}>
        <Content>
          <h2 className={`title is-5 has-text-centered ${classNames.title}`}>
          Select an event to view guests!
          </h2>
          <figure className={`image ${classNames.image}`}>
            <img src={calendar} alt='calendar' />
          </figure>
        </Content>
      </Card>
    </Column>
  );
}
