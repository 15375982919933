import React from "react";

export default function FeatureBox({ id, feature, description, thumb }) {
  return (
    <div
      key={id}
      style={{
        marginBottom: "10px",
        marginTop: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "1rem",
        backgroundColor: "#f9f9f9",
        transition: "box-shadow 0.2s ease",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      <article
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            marginRight: "1rem",
          }}
        >
          <figure
            style={{
              width: "128px",
              height: "128px",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <img
              src={thumb}
              alt={`${feature} thumbnail`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </figure>
        </div>
        <div
          style={{
            flex: "1",
          }}
        >
          <h3
            style={{
              fontSize: "1.5rem",
              margin: "0 0 0.5rem 0",
            }}
          >
            {feature}
          </h3>
          <p
            style={{
              color: "#555",
            }}
          >
            {description}
          </p>
        </div>
      </article>
    </div>
  );
}
