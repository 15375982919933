import React from "react";

import {
  Form
} from "react-bulma-components";
import classNames from "./InviteeForm.module.scss";

export default function InviteeForm({
  formData,
  onFormValueChange,
  additionalGuests,
  setAdditionalGuests,
}) {
  const { name, email, status } = formData;

  // State for additional guests
  // const [additionalGuests, setAdditionalGuests] = useState([]);

  // Handle adding a new guest input
  const handleAddGuest = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Check if the number of additionalGuests is less than 10
    if (additionalGuests?.length >= 10) {
      return; // Exit the function if there are already 10 guests
    }

    setAdditionalGuests([...additionalGuests, { name: "" }]);
  };

  // Handle changes in additional guest fields
  const handleGuestChange = (e, index) => {
    let { value } = e.target;

    // Create a copy of the additional guests array
    let newGuests = [...additionalGuests];

    // Create a copy of the individual guest object to avoid mutating a read-only object
    newGuests[index] = { ...newGuests[index], name: value };

    // Update the state with the modified guests array
    setAdditionalGuests(newGuests);
  };

  // Handle removing a guest input
  const handleRemoveGuest = (e, index) => {
    e.preventDefault(); // Prevent default form submission
    let newGuests = additionalGuests.filter((_, i) => i !== index);
    setAdditionalGuests(newGuests);
  };

  return (
    <div className={classNames.eventModalForm}>
      <Form.Field>
        <Form.Control>
          <Form.Input
            name="name"
            placeholder="Guest name"
            value={name}
            onChange={onFormValueChange}
            required
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Control>
          <Form.Input
            name="email"
            type="email"
            placeholder="Guest email"
            value={email}
            onChange={onFormValueChange}
            required
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Control>
          <div className={`select ${classNames.status}`}>
            <select
              name="status"
              value={status}
              onChange={onFormValueChange}
              className={classNames.status}
            >
              <option value="confirmed">Attending</option>
              <option value="unconfirm">Not attending</option>
            </select>
          </div>
        </Form.Control>
      </Form.Field>

      {/* Section for adding more guests with right-alignment */}
      <Form.Field>
        <Form.Control>
          <div className="is-flex is-justify-content-flex-end">
            <button
              onClick={handleAddGuest}
              className="button is-primary is-light"
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                padding: "0.5rem 1rem",
                fontWeight: "normal",
                marginBottom: "10px",
                backgroundColor: "#807a7a",
                fontSize: "13px",
                color: "#fff"
              }}
              disabled={additionalGuests?.length >= 10} // Disable the button when there are 10 guests
            >
              <span style={{ marginRight: "8px", fontSize: "1.2rem" }}>+</span>
              <span>Add additional guest</span>
            </button>
          </div>
        </Form.Control>
      </Form.Field>

      {/* Section for additional guests */}
      {additionalGuests.map((guest, index) => (
        <Form.Field key={index}>
          <Form.Control style={{ display: "flex", alignItems: "center" }}>
            <Form.Input
              name={`guestName-${index}`}
              placeholder={`Additional guest ${index + 1} name`}
              value={guest.name}
              onChange={(e) => handleGuestChange(e, index)}
              style={{ flex: 1 }} // Make the input take available space
            />
            <button
              onClick={(e) => handleRemoveGuest(e, index)}
              className="button is-danger is-light"
              style={{
                marginLeft: "10px",
                padding: "0.5rem 0.75rem", // Adjust padding for better look
                borderRadius: "4px",
                lineHeight: "1", // Center the X vertically
                minWidth: "30px", // Ensure a minimum width for the button
                backgroundColor: "#807a7a",
                color: "#fff"
              }}
            >
              X
            </button>
          </Form.Control>
        </Form.Field>
      ))}
    </div>
  );
}
