import React from "react";

import { useDispatch } from "react-redux";
import { openShareModal } from "../../store/actionCreators/modal";

export default function AddShareButton({ className, ...props }) {
  const dispatch = useDispatch();

  function openModal() {
    dispatch(openShareModal());
  }

  return (
    <button className={`button ${className} ${props.isMobile && 'is-white dropdown-item'}`} onClick={openModal} {...props}>
      <span class="icon">
        <i class="fa-solid fa-bullhorn"></i>
      </span>
      <span>Share Event</span>
    </button>
  );
}
