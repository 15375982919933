import React from "react";
import FeatureBox from "../FeatureBox/FeatureBox";

import features from "./features";
import { Link } from "react-router-dom";
import classNames from "./AppFeatures.module.css"

const testimonials = [
  {
    id: 1,
    name: "Alice",
    testimonial:
      "GetRSVPs.com has made event planning a breeze! The app is incredibly user-friendly, allowing me to manage guest lists without any clutter. I love how straightforward it is!",
  },
  {
    id: 2,
    name: "Rohit",
    testimonial:
      "This app does one thing exceptionally well—gathering RSVPs! It's so easy to use, and my guests appreciate the simplicity. I can't recommend it enough for hassle-free event management.",
  },
  {
    id: 3,
    name: "Sophia",
    testimonial:
      "Finally, an RSVP tool that is designed for ease! GetRSVPs.com is clean and simple, making it effortless for my guests to respond. It's a fantastic solution for anyone planning an event.",
  },
];

export default function AppFeatures({ ...props }) {
  const featureBoxes = features.map((feature) => (
    <div key={feature.id} className="column is-one-third">
      <FeatureBox {...feature} />
    </div>
  ));

  const sectionStyle = {
    backgroundColor: "#fff",
    padding: "1rem",
    borderRadius: "8px",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "2rem",
    marginBottom: "1.5rem",
  };

  const testimonialBoxStyle = {
    background: "white",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "1rem",
    margin: "0.5rem",
    width: "300px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const quoteStyle = {
    fontStyle: "italic",
    marginBottom: "0.5rem",
  };

  const authorStyle = {
    textAlign: "right",
    color: "#555",
  };

  return (
    <>
      <div className="navbar-item">
        <Link to="/signin" className={classNames.isMobileOnly}>
          Get started
        </Link>
      </div>
      <div {...props}>
        <div className="columns is-multiline">{featureBoxes}</div>
      </div>
      <section style={sectionStyle}>
        <h2 style={titleStyle}>What Our Users Say</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {testimonials.map(({ id, name, testimonial }) => (
            <div key={id} style={testimonialBoxStyle}>
              <p style={quoteStyle}>“{testimonial}”</p>
              <p style={authorStyle}>– {name}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
