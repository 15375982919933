import React from "react";
import classNames from "./Logo.module.css";

export default function Logo() {
  return (
    <div className={classNames.logo}>
      <img src="logo.jpg" alt="Logo Icon" className={classNames.logoImage} />
      <h1 className={classNames.logoTitle}>Get RSVPs</h1>
    </div>
  );
}
