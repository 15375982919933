import React from "react";

import { useDispatch } from "react-redux";
import { openEventModal } from "../../store/actionCreators/modal";
import {
  Button
} from "react-bulma-components";

export default function AddEventButton({ className, ...props }) {
  const dispatch = useDispatch();

  function openModal() {
    dispatch(openEventModal());
  }

  return (
    <Button
      onClick={openModal}
      color="dark"
      {...props}
    >
      Create Event
    </Button>
  );
}
