import { db } from "../../auth/firebase";
import { FETCH_EVENTS_SUCCESS, CLEAR_EVENTS } from "../actionTypes/event";

import { SET_UPDATABLE_EVENT } from "../actionTypes/eventAction";
import { closeModal } from "./modal";
import { clearSelectedEvent } from "./eventInfo";
import { clearUpdatableEvent } from "./eventAction";
import {
  displaySuccessNotification,
  displayErrorNotification,
} from "./notification";
// import { openShareModal } from "../../store/actionCreators/modal";

import { collection, query, where, onSnapshot } from "firebase/firestore";
import { doc, addDoc, deleteDoc, updateDoc } from "firebase/firestore";

function onFetchEventsSuccess(events) {
  return {
    type: FETCH_EVENTS_SUCCESS,
    payload: {
      events,
    },
  };
}

// function addEvent(eventInfo) {
//   return function (dispatch, getState) {
//     const { user } = getState().user;
//     const { uid } = user;

//     return new Promise(function (resolve, reject) {
//       db.collection("events")
//         .add({
//           uid,
//           ...eventInfo,
//         })
//         .then(function (docRef) {
//           dispatch(closeModal());
//           resolve(docRef.id);
//           dispatch(displaySuccessNotification("Event Successfully added!"));
//         })
//         .catch(function ({ message }) {
//           reject(message);
//           dispatch(displayErrorNotification(message));
//         });
//     });
//   };
// }

function addEvent(eventInfo) {
  return function (dispatch, getState) {
    const { user } = getState().user;
    const { uid } = user;

    return new Promise(async (resolve, reject) => {
      try {
        // Add a new event document to the "events" collection
        const docRef = await addDoc(collection(db, "events"), {
          uid,
          ...eventInfo,
        });

        // Update the document to include the unique ID as a field
        // Check if the document ID is available
        if (docRef.id) {
          // Update the document to include the unique ID as a field
          await updateDoc(docRef, {
            _id: docRef.id, // Set the _id field with the document ID
          });
        } else {
          throw new Error("Document ID is not available.");
        }

        // Dispatch actions for success
        dispatch(closeModal());
        dispatch(displaySuccessNotification("Event Successfully added!"));

        // Resolve with the document ID
        resolve(docRef.id);
      } catch (error) {
        // Handle errors
        const { message } = error;
        dispatch(displayErrorNotification(message));
        reject(message);
      }
    });
  };
}

// function deleteEvent(eventId) {
//   return function (dispatch, getState) {
//     const { selectedEvent } = getState().eventInfo;

//     if (selectedEvent._id === eventId) {
//       dispatch(clearSelectedEvent());
//     }

//     db.collection("events")
//       .doc(eventId)
//       .delete()
//       .then(function () {
//         dispatch(displaySuccessNotification("Event Successfully delete!"));
//       })
//       .catch(function ({ message }) {
//         dispatch(displayErrorNotification(message));
//       });
//   };
// }

function deleteEvent(eventId) {
  return function (dispatch, getState) {
    const { selectedEvent } = getState().eventInfo;

    // Clear selected event if it's the one being deleted
    if (selectedEvent && selectedEvent._id === eventId) {
      dispatch(clearSelectedEvent());
    }

    // Reference the document to be deleted and perform the delete action
    deleteDoc(doc(db, "events", eventId))
      .then(() => {
        // Dispatch success notification
        dispatch(displaySuccessNotification("Event Successfully deleted!"));
      })
      .catch((error) => {
        // Dispatch error notification in case of failure
        dispatch(displayErrorNotification(error.message));
      });
  };
}

// function fetchEvents() {
//   return (dispatch, getState) => {
//     var eventsRef = db.collection("events");
//     const { user } = getState().user;
//     const { uid } = user;

//     var query = eventsRef.where("uid", "==", uid);

//     const unsubscribe = query.onSnapshot(
//       {
//         // Listen for document metadata changes
//         includeMetadataChanges: true,
//       },
//       function (querySnapshot) {
//         var events = [];
//         querySnapshot.forEach(function (doc) {
//           events.push({
//             ...doc.data(),
//             _id: doc.id,
//           });
//         });
//         dispatch(onFetchEventsSuccess(events));
//       }
//     );

//     return unsubscribe;
//   };
// }

function fetchEvents() {
  return (dispatch, getState) => {
    const { user } = getState().user;
    const { uid } = user;

    // Reference to the "events" collection
    const eventsRef = collection(db, "events");

    // Create query to filter events by user uid
    const eventsQuery = query(eventsRef, where("uid", "==", uid));

    // Set up listener for snapshot changes
    const unsubscribe = onSnapshot(
      eventsQuery,
      { includeMetadataChanges: true }, // Listen for metadata changes
      (querySnapshot) => {
        const events = [];

        // Iterate over query results and push data to the events array
        querySnapshot.forEach((doc) => {
          events.push({
            ...doc.data(),
            _id: doc.id,
          });
        });

        // Dispatch the fetched events to the redux store
        dispatch(onFetchEventsSuccess(events));
      },
      (error) => {
        console.error("Error fetching events: ", error);
      }
    );

    // Return unsubscribe function to clean up listener
    return unsubscribe;
  };
}

function clearEvents() {
  return {
    type: CLEAR_EVENTS,
  };
}

// function updateEvent(eventInfo) {
//   return function (dispatch, getState) {
//     return new Promise(function (resolve, reject) {
//       db.collection("events")
//         .doc(eventInfo._id)
//         .update({
//           ...eventInfo,
//         })
//         .then(function () {
//           resolve("update");
//           dispatch(closeModal());
//           dispatch(clearUpdatableEvent());
//           dispatch(displaySuccessNotification("Event Successfully updated!"));
//         })
//         .catch(function ({ message }) {
//           dispatch(displayErrorNotification(message));
//         });
//     });
//   };
// }

function updateEvent(eventInfo) {
  return function (dispatch, getState) {
    return new Promise(async (resolve, reject) => {
      try {
        // Reference the document to be updated
        const eventDocRef = doc(db, "events", eventInfo._id);

        // Update the document with new data
        await updateDoc(eventDocRef, {
          ...eventInfo,
        });

        // Dispatch actions for success
        dispatch(closeModal());
        dispatch(clearUpdatableEvent());
        dispatch(displaySuccessNotification("Event Successfully updated!"));

        // Resolve the promise
        resolve("update");
      } catch (error) {
        // Handle errors
        const { message } = error;
        dispatch(displayErrorNotification(message));
        reject(message);
      }
    });
  };
}

function onsetUpdatableEvent(updatableEvent) {
  return {
    type: SET_UPDATABLE_EVENT,
    payload: {
      updatableEvent,
    },
  };
}

function setUpdatableEvent(eventId) {
  return function (dispatch, getState) {
    const { events } = getState().event;
    const updatableEvent = events.find((event) => event._id === eventId);
    // dispatch(openEventModal());
    dispatch(onsetUpdatableEvent(updatableEvent));
  };
}

export {
  addEvent,
  fetchEvents,
  clearEvents,
  deleteEvent,
  setUpdatableEvent,
  updateEvent,
};
