import { db } from "../../auth/firebase";
import { SELECT_EVENT, CLEAR_SELECTED_EVENT } from "../actionTypes/eventInfo";
import { collection, doc, onSnapshot } from "firebase/firestore";

function onSelectEvent(event) {
  return {
    type: SELECT_EVENT,
    payload: {
      event,
    },
  };
}

// function selectEvent(eventId) {
//   return (dispatch, getState) => {
//     const { events } = getState().event;

//     const selected = events.find((event) => event._id === eventId);

//     const { _id } = selected;

//     var query = db.collection("events").doc(_id).collection("invitees");

//     const unsubscribe = query.onSnapshot(function (querySnapshot) {
//       var invitees = [];
//       querySnapshot.forEach(function (doc) {
//         invitees.push({
//           ...doc.data(),
//           _id: doc.id,
//         });
//       });

//       let completeEvent = {
//         ...selected,
//         invitees,
//       };

//       dispatch(onSelectEvent(completeEvent));
//     });

//     return unsubscribe;
//   };
// }

function selectEvent(eventId) {
  return (dispatch, getState) => {
    const { events } = getState().event;

    // Find the selected event by its ID
    const selected = events.find((event) => event._id === eventId);

    if (!selected) {
      console.error("Event not found!");
      return;
    }

    const { _id } = selected;

    // Reference the invitees collection for the selected event
    const inviteesRef = collection(doc(db, "events", _id), "invitees");

    // Subscribe to real-time updates from the invitees collection
    const unsubscribe = onSnapshot(inviteesRef, (querySnapshot) => {
      const invitees = [];
      querySnapshot.forEach((doc) => {
        invitees.push({
          ...doc.data(),
          _id: doc.id,
        });
      });

      // Create a complete event object including invitees
      let completeEvent = {
        ...selected,
        invitees,
      };

      // Dispatch the complete event to the store
      dispatch(onSelectEvent(completeEvent));
    });

    return unsubscribe; // Return the unsubscribe function
  };
}

function clearSelectedEvent() {
  return {
    type: CLEAR_SELECTED_EVENT,
  };
}

export { selectEvent, clearSelectedEvent };
