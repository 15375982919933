import React from "react";
// import List from "react-bulma-components";

import InviteeButtons from "../InviteeButtons/InviteeButtons";

import classNames from "./Invitee.module.css";

// const { Item } = List;

export default function Invitee({ name, _id, additionalGuests, guestNote }) {
  return (
    <ul className={classNames.item}>
      <div className={classNames.guestInfo}>
        {name}{" "}
        {additionalGuests?.length > 0 ? ` + ${additionalGuests.length}` : ""}
        <p className={classNames.guestNote}>{guestNote}</p>
      </div>
      <InviteeButtons inviteeId={_id} name={name} />
    </ul>
  );
}
