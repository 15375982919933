import plan from "../../assets/images/plan.svg";
import people from "../../assets/images/people.svg";
import happy from "../../assets/images/happy.svg";

export default [
  {
    id: 1,
    feature: "Effortless Event Creation",
    description: "Easily add as many events as you like, all displayed in a convenient list for quick access.",
    thumb: plan,
  },
  {
    id: 2,
    feature: "Seamless Guest Management",
    description: "Click on any event to access a dedicated section for adding and managing your guests with ease.",
    thumb: people,
  },
  {
    id: 3,
    feature: "Collect RSVPs with ease",
    description: "With unique event links, accept RSVPs from your guests with needed details with easy to use UI.",
    thumb: happy,
  }
  
];
