import React from "react";
import { useSelector } from "react-redux";

import { Card } from "react-bulma-components";
import AddInviteeButton from "../../AddInviteeButton/AddInviteeButton";
import AddShareButton from "../../AddShareButton/AddShareButton";
import AddExportButton from "../../AddExportButton/AddExportButton";
import classNames from "./EventInfoHeader.module.css";
const { Header } = Card;

const { Icon } = Header;

export default function EventMenu() {
  const { selectedEvent } = useSelector((state) => state.eventInfo);
  const { title } = selectedEvent;

  return (
    <Header className={classNames.cardHeaderTitle}>
      <p className={`card-header-title ${classNames.cardHeaderTitle}`}>
        <span className="title is-5">{title}</span>
      </p>
      <Icon className={classNames.isMobile}>
        <AddExportButton />
      </Icon>
      <Icon className={classNames.isMobile}>
        <AddShareButton />
      </Icon>
      <Icon className={classNames.isMobile}>
        <AddInviteeButton />
      </Icon>
    </Header>
  );
}
