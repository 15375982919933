import React from "react";
import { Form } from "react-bulma-components";

import { Image } from "react-bulma-components";
import TimezoneSelect from "react-timezone-select"; // Import the timezone selector

//styles
import styles from "./EventForm.module.css";

export default function AddEventForm({ formData, onFormValueChange }) {
  const {
    title,
    description,
    date,
    startTime,
    endTime,
    timezone,
    venue,
    uploadedPhoto,
    active,
    showGuestList,
    allowGuestNote
  } = formData;

  // This function is specifically for handling timezone selection
  function onTimezoneChange(selectedTimezone) {
    formData.timezone = selectedTimezone; // Assuming you want to store the timezone value
  }

  return (
    <form action="" className={styles.eventModalForm}>
      <Form.Field>
        <Form.Label>Title</Form.Label>
        <Form.Control>
          <Form.Input
            name="title"
            type="text"
            placeholder="Join us for Kim's Birthday celebration"
            value={title}
            onChange={onFormValueChange}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Description</Form.Label>
        <Form.Control>
          <Form.Textarea
            name="description"
            rows="2"
            maxLength="500"
            type="text"
            placeholder="Join us for a joyous birthday celebration as we toast to another year of adventures! Cake, laughter, and unforgettable memories await!"
            value={description}
            onChange={onFormValueChange}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field style={{ flex: "1", marginRight: "10px" }}>
        <Form.Label>Date</Form.Label>
        <Form.Control>
          <Form.Input
            name="date"
            type="date"
            placeholder=""
            value={date}
            onChange={onFormValueChange}
          />
        </Form.Control>
      </Form.Field>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Form.Field style={{ flex: "1", marginRight: "10px" }}>
          <Form.Label>Start time</Form.Label>
          <Form.Control>
            <Form.Input
              name="startTime"
              type="time"
              placeholder=""
              value={startTime}
              onChange={onFormValueChange}
            />
          </Form.Control>
        </Form.Field>
        <Form.Field style={{ flex: "1", marginLeft: "10px" }}>
          <Form.Label>End time</Form.Label>
          <Form.Control>
            <Form.Input
              name="endTime"
              type="time"
              placeholder=""
              value={endTime}
              onChange={onFormValueChange}
            />
          </Form.Control>
        </Form.Field>
      </div>
      <Form.Field style={{ marginTop: "10px" }}>
        <Form.Label>Time Zone</Form.Label>
        <Form.Control>
          <TimezoneSelect
            value={timezone?.value}
            onChange={onTimezoneChange} // Update the timezone state
            placeholder="Select a timezone"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label>Venue</Form.Label>
        <Form.Control>
          <Form.Input
            name="venue"
            type="text"
            placeholder="Richard's Cafe, Main Road, New York, 10018"
            value={venue}
            onChange={onFormValueChange}
          />
        </Form.Control>
      </Form.Field>
      <div style={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
        <Form.Field style={{ flex: 1, marginRight: "20px" }}>
          <Form.Label>Event photo</Form.Label>
          <Form.Control>
            <Form.InputFile name="photo" onChange={onFormValueChange} />
          </Form.Control>
        </Form.Field>
        <Form.Field style={{ flex: 1 }}>
          <Form.Control
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {uploadedPhoto && (
              <Image size={64} fallback={uploadedPhoto} src={uploadedPhoto} />
            )}
          </Form.Control>
        </Form.Field>
      </div>
      <Form.Field>
        <Form.Control>
          <Form.Checkbox
            name="active"
            checked={active}
            onChange={onFormValueChange}
          />
          {"  "}
          <strong>Start collection RSVPs</strong>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Control>
          <Form.Checkbox
            name="showGuestList"
            checked={showGuestList}
            onChange={onFormValueChange}
          />
          {"  "}
          <strong>Show RSVPs from other guests</strong>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Control>
          <Form.Checkbox
            name="allowGuestNote"
            checked={allowGuestNote}
            onChange={onFormValueChange}
          />
          {"  "}
          <strong>Allow guests to leave a note</strong>
        </Form.Control>
      </Form.Field>
    </form>
  );
}
