import React from "react";
import { useDispatch, useSelector } from "react-redux";

//components
import { Modal } from "react-bulma-components";
import { Card } from "react-bulma-components";
import { getSharableLink } from "./../Utility/guestUtils";

import { closeModal } from "../../store/actionCreators/modal";

import { ShareSocial } from "react-share-social";

//styles
import styles from "./ShareModal.module.css";

const style = {
  root: {
    // background: '#ddd',
    // borderRadius: 3,
    // border: 0,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // color: 'white',
  },
  copyContainer: {
    background: "rgb(0,0,0,0.7)",
  },
  iconContainer: {
    paddingTop: 0,
  },
  title: {
    color: "aquamarine",
    fontStyle: "italic",
  },
};

const { Header, Footer, Content } = Card;
const { Title } = Header;

export default function AddShareModal() {
  const dispatch = useDispatch();
  const { currentlyOpenModal } = useSelector((state) => state.modal);
  const { updatableInvitee } = useSelector((state) => state.invitee);

  const { selectedEvent } = useSelector((state) => state.eventInfo);

  // const { events } = useSelector((state) => state.event);
  // const specificEvent = events.find((event) => event._id === _id);

  const urlToShare = getSharableLink(selectedEvent);

  function onModalClose() {
    dispatch(closeModal());
  }

  return (
    <Modal
      show={currentlyOpenModal === "share" || updatableInvitee !== null}
      onClose={onModalClose}
      closeOnEsc={true}
      showClose={false}
    >
      <Modal.Card className={styles.shareModal}>
        <Modal.Card.Header>
          <Modal.Card.Title style={{ fontSize: "20px", fontWeight: "bold"}}>Share with friends and family</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Content className={styles.eventModalContent}>
          <ShareSocial
            url={urlToShare}
            socialTypes={[
              "facebook",
              "twitter",
              "whatsapp",
              "linkedin",
              "telegram",
              "line",
              "email",
              "reddit",
            ]}
            style={style}
          />
        </Modal.Content>
      </Modal.Card>
    </Modal>
  );
}
