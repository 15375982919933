import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, addDoc, getDocs } from "firebase/firestore";
import { db } from "../../auth/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Form,
  Container,
  Heading,
  Section,
  Box,
  Notification,
  Columns,
  Image,
} from "react-bulma-components";
import { Card } from "react-bulma-components";
import { Modal } from "react-bulma-components";
import InvalidEventPage from "./InvalidEventPage";
import classNames from "./InviteeForm.module.scss";
import {
  convert24HourStringToTime,
  formatDateString,
  formatStartDateTimeToIso,
  formatEndDateTimeToIso,
  getSharableLink,
} from "../Utility/guestUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClock,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { Helmet } from "react-helmet";
const { Input, Field, Label, Control } = Form;
const { Header, Footer, Content } = Card;
const { Title } = Header;

const RSVPPage = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showGuestListModal, setShowGuestListModal] = useState(false);
  const [invitees, setInvitees] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    status: "confirmed",
    createdAt: "",
    guestNote: "",
    additionalGuests: [],
  });
  const [captchaValue, setCaptchaValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, "events", eventId);
        const eventSnapshot = await getDoc(eventRef);
        if (eventSnapshot.exists()) {
          setEvent(eventSnapshot.data());
        } else {
          setError("Event not found.");
        }
      } catch (err) {
        console.error("Error fetching event:", err);
        setError("Error fetching event.");
      }
    };
    fetchEvent();
  }, [eventId]);

  const fetchInvitees = async () => {
    try {
      const inviteesRef = collection(db, "events", eventId, "invitees");
      const inviteesSnapshot = await getDocs(inviteesRef);
      const inviteesList = inviteesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInvitees(inviteesList);
    } catch (err) {
      console.error("Error fetching invitees:", err);
      setError("Error fetching guest list.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAdditionalGuestChange = (e, index) => {
    e.preventDefault();
    const { value } = e.target;
    const updatedGuests = [...formData.additionalGuests];
    updatedGuests[index].name = value;

    setFormData((prev) => ({
      ...prev,
      additionalGuests: updatedGuests,
    }));
  };

  const handleAddGuest = (e) => {
    e.preventDefault();
    if (formData.additionalGuests.length >= 10) return;
    setFormData((prev) => ({
      ...prev,
      additionalGuests: [...prev.additionalGuests, { name: "" }],
    }));
  };

  const handleRemoveGuest = (e, index) => {
    e.preventDefault();
    const updatedGuests = formData.additionalGuests.filter(
      (_, i) => i !== index
    );
    setFormData((prev) => ({
      ...prev,
      additionalGuests: updatedGuests,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email) {
      setError("Please fill out all fields.");
      return;
    }
    formData.createdAt = new Date();

    formData.eventId = eventId;

    const emptyNameGuest = formData.additionalGuests.find(
      (guest) => !guest.name.trim()
    );

    if (emptyNameGuest) {
      setError("Guest name(s) missing.");
      return false; // Returning false if there's an error
    }

    // Prevent multiple submissions
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      await addDoc(collection(db, "events", eventId, "invitees"), {
        ...formData,
        timestamp: new Date(),
      });
      setSubmitted(true);
      setError(null);
    } catch (err) {
      console.log("Error submitting RSVP:", err);
      setError("Error submitting RSVP. Please try again after sometime.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleModal = () => setShowModal(!showModal);
  const toggleGuestListModal = () => {
    setShowGuestListModal(!showGuestListModal);
    if (!showGuestListModal) {
      fetchInvitees();
    }
  };

  if (event?.active === false || error === "Event not found.") {
    return <InvalidEventPage />;
  }

  // const eventInfo = {
  //   title: 'Your Event Title',
  //   description: 'Event Description',
  //   location: 'Event Location',
  //   startTime: new Date().toISOString(), // Adjust to your event's start time
  //   endTime: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(), // Adjust to your event's end time
  // };

  if (!event)
    return (
      <Section>
        <Container>
          <Box className={classNames.eventContainer}>
            <Notification
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Loading event details
            </Notification>
          </Box>
        </Container>
      </Section>
    );

  return (
    <Section className={classNames.rsvpMainSection}>
      {event && (
        <Helmet>
          <title>{event.title || "RSVP Event"}</title>
          <meta
            name="description"
            content={event.description || "Event description"}
          />
          <meta property="og:title" content={event.title || "RSVP Event"} />
          <meta
            property="og:description"
            content={event.description || "Event description"}
          />
          <meta
            property="og:image"
            content={event.uploadedPhoto || "default-image.jpg"}
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
      )}
      <Container>
        <Box className={classNames.eventContainer}>
          <Columns className="is-variable is-8">
            <Columns.Column size={5}>
              <Image
                src={
                  event?.uploadedPhoto ||
                  "https://img.freepik.com/premium-photo/festive-vertical-display-adorned-with-balloons-gifts-holiday-celebration-generative-ai_437323-28723.jpg"
                }
                alt="Event"
              />
            </Columns.Column>

            <Columns.Column size={7}>
              <Heading style={{ textAlign: "center" }} size={3}>
                {event?.title}
              </Heading>
              <Box>
                <p>{event?.description}</p>
              </Box>
              <Box>
                <p>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    icon={faCalendarAlt}
                  />
                  {"  "}
                  {formatDateString(event?.date)}
                </p>
              </Box>
              <Box>
                <p>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    icon={faClock}
                  />
                  {"  "}
                  {convert24HourStringToTime(event?.startTime)} to{" "}
                  {convert24HourStringToTime(event?.endTime)}{" "}
                  {event?.timezone?.abbrev}
                </p>
              </Box>
              <Box>
                <p>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    icon={faMapMarkerAlt}
                  />
                  {"  "}
                  {event?.venue}
                </p>
              </Box>
              {event?.showGuestList === true && (
                <Button
                  className={classNames.viewGuestButton}
                  color="text"
                  fullwidth={true}
                  onClick={toggleGuestListModal}
                >
                  View Guest List
                </Button>
              )}
              <Button color="dark" fullwidth={true} onClick={toggleModal}>
                <strong>Let us know your plan</strong>
              </Button>
            </Columns.Column>
          </Columns>
        </Box>

        <Modal
          show={showModal}
          onClose={toggleModal}
          closeOnEsc={true}
          showClose={false}
        >
          <Modal.Card className={classNames.eventModal}>
            {!submitted ? (
              <Modal.Card.Header>
                <Modal.Card.Title
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  Let us know your plan.
                </Modal.Card.Title>
              </Modal.Card.Header>
            ) : (
              <Modal.Card.Header>
                <Modal.Card.Title
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  Thank you for RSVP!
                </Modal.Card.Title>
              </Modal.Card.Header>
            )}
            <Modal.Content className={classNames.modalContent}>
              <RSVPFormModal
                formData={formData}
                event={event}
                handleInputChange={handleInputChange}
                handleAddGuest={handleAddGuest}
                handleRemoveGuest={handleRemoveGuest}
                handleAdditionalGuestChange={handleAdditionalGuestChange}
                handleSubmit={handleSubmit}
                setCaptchaValue={setCaptchaValue}
                error={error}
                submitted={submitted}
              />
            </Modal.Content>
          </Modal.Card>
        </Modal>

        <Modal
          show={showGuestListModal}
          onClose={toggleGuestListModal}
          closeOnEsc={true}
          showClose={false}
        >
          <Modal.Card className={classNames.eventModal}>
            <Modal.Card.Header>
              <Modal.Card.Title
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                Check who else is coming ...
              </Modal.Card.Title>
            </Modal.Card.Header>
            <Modal.Content className={classNames.modalContent}>
              {invitees.length === 0 ? (
                <p style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  No guests have RSVP'd yet.
                </p>
              ) : (
                <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                  <table className="table is-fullwidth is-striped">
                    <thead>
                      <tr>
                        <th className={classNames.tableTh}>Attending</th>
                        <th className={classNames.tableTh}>Not attending</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.from(
                        {
                          length: Math.max(
                            invitees.filter(
                              (invitee) => invitee.status === "confirmed"
                            ).length,
                            invitees.filter(
                              (invitee) => invitee.status === "unconfirm"
                            ).length
                          ),
                        },
                        (_, index) => (
                          <tr key={index}>
                            <td>
                              {invitees.filter(
                                (invitee) => invitee.status === "confirmed"
                              )[index]
                                ? invitees.filter(
                                    (invitee) => invitee.status === "confirmed"
                                  )[index].name +
                                  (invitees.filter(
                                    (invitee) => invitee.status === "confirmed"
                                  )[index].additionalGuests.length > 0
                                    ? ` + ${
                                        invitees.filter(
                                          (invitee) =>
                                            invitee.status === "confirmed"
                                        )[index].additionalGuests.length
                                      } guest(s)`
                                    : "")
                                : ""}
                            </td>
                            <td>
                              {invitees.filter(
                                (invitee) => invitee.status === "unconfirm"
                              )[index]
                                ? invitees.filter(
                                    (invitee) => invitee.status === "unconfirm"
                                  )[index].name +
                                  (invitees.filter(
                                    (invitee) => invitee.status === "unconfirm"
                                  )[index].additionalGuests.length > 0
                                    ? ` + ${
                                        invitees.filter(
                                          (invitee) =>
                                            invitee.status === "unconfirm"
                                        )[index].additionalGuests.length
                                      } guest(s)`
                                    : "")
                                : ""}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </Modal.Content>
          </Modal.Card>
        </Modal>
      </Container>
    </Section>
  );
};

const RSVPFormModal = ({
  formData,
  event,
  handleInputChange,
  handleAddGuest,
  handleRemoveGuest,
  handleAdditionalGuestChange,
  handleSubmit,
  setCaptchaValue,
  error,
  submitted,
}) => (
  <Box className={classNames.rsvpBox}>
    {submitted ? (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* <Notification align="center" color="success">
            Thank you for your RSVP!
          </Notification> */}
          <p style={{ paddingBottom: "12px" }}>Add event to your calendar</p>
          <AddToCalendarButton
            name={event?.title}
            description={
              event?.description +
              "\n\n" +
              "If you need to change your plans, please contact the host to assist them in adjusting the event arrangements accordingly." +
              "\n\n" +
              getSharableLink(event)
            }
            startDate={formatStartDateTimeToIso(
              event?.date,
              event?.startTime,
              event?.timezone
            )}
            endDate={formatEndDateTimeToIso(
              event?.date,
              event?.endTime,
              event?.timezone
            )}
            location={event?.venue}
            timeZone={event?.timezone?.value}
            options={["Apple", "Google", "Yahoo", "iCal"]}
            listStyle="overlay"
            buttonsList
            size="4"
          />
        </div>
      </>
    ) : (
      <form onSubmit={handleSubmit} className={classNames.eventModalForm}>
        <Form.Field>
          <Form.Control>
            <Form.Input
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Control>
        </Form.Field>
        <Form.Field>
          <Form.Control>
            <Form.Input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Control>
        </Form.Field>
        {event?.allowGuestNote === true && (
          <Form.Field>
            <Form.Control>
              <Form.Input
                type="text"
                name="guestNote"
                maxLength={100}
                placeholder="Message for the Host"
                value={formData.guestNote}
                onChange={handleInputChange}
              />
            </Form.Control>
          </Form.Field>
        )}
        <Form.Field>
          <Form.Control>
            <div className={`select ${classNames.status}`}>
              <select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                className={classNames.status}
              >
                <option value="confirmed">Yes, I will be there</option>
                <option value="unconfirm">Sorry, I can't attend</option>
              </select>
            </div>
          </Form.Control>
        </Form.Field>
        {formData.additionalGuests.map((guest, index) => (
          <Form.Field key={index}>
            <Form.Control style={{ display: "flex", alignItems: "center" }}>
              <Form.Input
                placeholder={`Guest ${index + 1} Name`}
                value={guest.name}
                onChange={(e) => handleAdditionalGuestChange(e, index)}
                style={{ flex: 1 }}
              />
              <Button
                color="danger"
                onClick={(e) => handleRemoveGuest(e, index)}
                style={{ marginLeft: "10px" }}
              >
                X
              </Button>
            </Form.Control>
          </Form.Field>
        ))}
        {formData?.status === "confirmed" && (
          <Form.Field>
            <Form.Control
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={handleAddGuest}
                size="small"
                color="black-ter"
                disabled={formData.additionalGuests.length >= 10}
              >
                Add another guest
              </Button>
            </Form.Control>
          </Form.Field>
        )}
        <Form.Field className="has-text-centered">
          <Form.Control>
            <Button
              color="dark"
              disabled={submitted ? true : false}
              fullwidth={true}
              type="submit"
            >
              <strong>Submit RSVP</strong>
            </Button>
          </Form.Control>
        </Form.Field>
        {error && <Notification color="danger">{error}</Notification>}
      </form>
    )}
  </Box>
);

export default RSVPPage;
