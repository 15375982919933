import React from "react";
import classNames from "./Footer.module.css";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation(); // Get the current route location
  return (
    <>
      {location.pathname.includes("/rsvp") && (
        <footer className={`${classNames.footer} card-footer`}>
          <p className={`card-footer-item ${classNames.cardFooterItem}`}>
            Made with <a href="https://getrsvps.com">GetRSVPs</a>
          </p>
        </footer>
      )}
    </>
  );
}
