import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import classNames from "./App.module.css";

// Components
import Home from "./Home/Home";
import NavBar from "./Navbar/Navbar";
import DropDown from "./Dropdown/Dropdown";
import Signin from "./Signin/Signin";
import Dashboard from "./Dashboard/Dashboard";
import RSVPPage from "./Rsvp/RSVPPage";
import EventModal from "./EventModal/EventModal";
import InviteeModal from "./InviteeModal/InviteeModal";
import ShareModal from "./ShareModal/ShareModal";
import EventIntro from "./EventIntro/EventIntro";
import Footer from "./Footer/Footer";
import Notification from "./Notification/Notification";

// Action creators
import { userLoginSuccess } from "../store/actionCreators/user";
import { fetchEvents } from "../store/actionCreators/event";
import { closeDropdown } from "../store/actionCreators/dropdown";

import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import necessary Firebase methods

// AppContent component handles the main content and location-based logic
function AppContent() {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current route location

  // Handle clicks on the background to close the dropdown
  function handleBackgroundClick() {
    dispatch(closeDropdown());
  }

  return (
    <div
      className={`${classNames.app}`}
      onClick={handleBackgroundClick}
    >
      {/* Conditionally render the NavBar based on route */}
      {(!location.pathname.includes("/rsvp") && !location.pathname.includes("/invite")) && <NavBar />}

      <div className={`${classNames.cardContent}`}>
        <ShareModal />
        <EventModal />
        <InviteeModal />
        <DropDown />
        <Notification />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/intro" component={EventIntro} />
          <Route path="/rsvp/:eventId" component={RSVPPage} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default function App() {
  const dispatch = useDispatch();
  const auth = getAuth(); // Get the Firebase auth instance

  useEffect(() => {
    const unsubscribeFromAuth = onAuthStateChanged(auth, (loggedInUser) => {
      if (loggedInUser) {
        dispatch(userLoginSuccess(loggedInUser)); // Dispatch login success action
        const unsubscribeFromEvents = dispatch(fetchEvents()); // Fetch events for the logged-in user

        return () => {
          unsubscribeFromEvents(); // Clean up events listener if needed
        };
      }
    });

    // Cleanup auth state listener on component unmount
    return () => {
      unsubscribeFromAuth();
    };
  }, [auth, dispatch]); // Add auth and dispatch as dependencies to avoid potential stale closures

  return (
    <React.StrictMode>
      <Router>
        <AppContent /> {/* Wrap AppContent inside Router */}
      </Router>
    </React.StrictMode>
  );
}
