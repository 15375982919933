import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment-timezone';

/**
 * Calculates the total number of guests including additional guests.
 * @param {Array} invitees - The list of invitees.
 * @returns {number} The total number of guests.
 */
export const calculateTotalGuests = (invitees) => {
  let totalGuests = 0;
  invitees.forEach((guest) => {
    totalGuests += 1; // Count the main guest
    totalGuests += guest?.additionalGuests?.length || 0; // Count additional guests, if any
  });
  return totalGuests;
};

export const convert24HourStringToTime = (timeStr) => {
  // Split the input string into hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Determine AM/PM
  const period = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const hour12 = hours % 12 || 12;

  // Pad the minutes with leading zero if needed
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Format the time string
  return `${hour12}.${formattedMinutes}${period}`;
};

export const formatDateString = (dateString) => {
  const updatedDateString = `${dateString}T00:00:00`;
  const date = new Date(updatedDateString);
  
  // Extract day of the week, month, day, and year
  const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' }); // Full month name
  const year = date.getFullYear();

  // Add ordinal suffix to the day
  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Special case for 11-13
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${dayOfWeek}, ${month} ${day}${ordinalSuffix(day)}, ${year}`;
}

export const formatStartDateTimeToIso = (date, startTime, timezone) => {
  // Combine the date and time into a single string
  const dateTimeString = `${date}T${startTime}:00`;

  // Parse the combined date and time string in UTC
  const formattedDateTime = moment.utc(dateTimeString);

  // Return the ISO string representation in UTC
  return formattedDateTime.toISOString();
};

export const formatEndDateTimeToIso = (date, endTime, timeZone) => {
  // Combine the date and time into a single string and parse it
  const dateTimeString = `${date}T${endTime}`;
  
  // Parse the combined date and time string in UTC
  const formattedDateTime = moment.utc(dateTimeString);

  // Return the ISO string representation in UTC
  return formattedDateTime.toISOString();
};

export const getSharableLink = (selectedEvent) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return `${baseUrl}/invite/${selectedEvent?._id}`;
};

// Function to export to Excel
// export const exportToExcel = (data) => {
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
//   XLSX.writeFile(workbook, "data.xlsx");
// };

export const exportToCSV = (selectedEvent) => {
  // Prepare the CSV data with additional columns
  const csvData = selectedEvent.invitees.map((invitee) => {
    const additionalGuests = invitee.additionalGuests
      .map((guest) => guest.name)
      .join(", ");
    const totalGuests = invitee.additionalGuests.length + 1; // 1 for the main invitee

    return {
      Name: invitee.name,
      Email: invitee.email,
      Status: invitee.status,
      AdditionalGuests: additionalGuests,
      TotalGuests: totalGuests,
    };
  });

  // Sort the data to move "unconfirm" guests to the end
  csvData.sort((a, b) => {
    if (a.Status === "unconfirm" && b.Status !== "unconfirm") {
      return 1;
    } else if (a.Status !== "unconfirm" && b.Status === "unconfirm") {
      return -1;
    } else {
      return 0;
    }
  });

  // Calculate total guests, confirmed guests, and unconfirmed guests
  const totalGuestsSum = csvData.reduce(
    (total, row) => total + row.TotalGuests,
    0
  );
  const confirmedGuestsSum = csvData.reduce(
    (total, row) =>
      row.Status === "confirmed" ? total + row.TotalGuests : total,
    0
  );
  const unconfirmedGuestsSum = csvData.reduce(
    (total, row) =>
      row.Status === "unconfirm" ? total + row.TotalGuests : total,
    0
  );

  // Add breakdown rows for confirmed and unconfirmed guests
  csvData.push({
    Name: "Total Confirmed",
    Email: "",
    Status: "",
    AdditionalGuests: "",
    TotalGuests: confirmedGuestsSum,
  });

  csvData.push({
    Name: "Total Unconfirmed",
    Email: "",
    Status: "",
    AdditionalGuests: "",
    TotalGuests: unconfirmedGuestsSum,
  });

  // Add a row at the end for the total guests
  csvData.push({
    Name: "Total",
    Email: "",
    Status: "",
    AdditionalGuests: "",
    TotalGuests: totalGuestsSum,
  });

  // Convert the data to CSV format
  const worksheet = XLSX.utils.json_to_sheet(csvData);
  const csvContent = XLSX.utils.sheet_to_csv(worksheet);

  // Create a Blob from the CSV data
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${selectedEvent.title} Guests.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after download
  }
};


// Function to export to PDF
export const exportToPDF = (selectedEvent) => {
  // Prepare the PDF data with additional columns
  const pdfData = selectedEvent.invitees.map((invitee) => {
    const additionalGuests = invitee.additionalGuests
      .map((guest) => guest.name)
      .join(", ");
    const totalGuests = invitee.additionalGuests.length + 1; // 1 for the main invitee

    return [
      invitee.name, // Name
      invitee.email, // Email
      invitee.status, // Status
      additionalGuests, // Additional Guests
      totalGuests, // Total Guests
    ];
  });

  // Sort the data to move "unconfirm" guests to the end
  pdfData.sort((a, b) => {
    if (a[2] === "unconfirm" && b[2] !== "unconfirm") {
      return 1;
    } else if (a[2] !== "unconfirm" && b[2] === "unconfirm") {
      return -1;
    } else {
      return 0;
    }
  });

  // Calculate total guests, confirmed guests, and unconfirmed guests
  const totalGuestsSum = pdfData.reduce((total, row) => total + row[4], 0);
  const confirmedGuestsSum = pdfData.reduce(
    (total, row) => (row[2] === "confirmed" ? total + row[4] : total),
    0
  );
  const unconfirmedGuestsSum = pdfData.reduce(
    (total, row) => (row[2] === "unconfirm" ? total + row[4] : total),
    0
  );

  // Add breakdown rows for confirmed and unconfirmed guests
  pdfData.push(["Total Confirmed", "", "", "", confirmedGuestsSum]);
  pdfData.push(["Total Unconfirmed", "", "", "", unconfirmedGuestsSum]);

  // Add the total guests row at the end
  pdfData.push(["Total", "", "", "", totalGuestsSum]);

  // Create a new jsPDF instance
  const doc = new jsPDF();

  // Add a title
  doc.text("Invitees List", 14, 20);

  // Define the table headers
  const headers = [
    ["Name", "Email", "Status", "Additional Guests", "Total Guests"],
  ];

  // Generate the table with the invitee data
  doc.autoTable({
    startY: 30,
    head: headers,
    body: pdfData,
  });

  // Save the PDF
  doc.save(`${selectedEvent.title} Guests.pdf`);
};

