import React from "react";

import { Card } from "react-bulma-components";
// import List from "react-bulma-components";

import InviteeListHeader from "./InviteeListHeader/InviteeListHeader";
import Invitee from "../Invitee/Invitee";

const { Content } = Card;

export default function InviteeList({ title, invitees }) {
  let inviteeList = [];
  const isEmpty = invitees.length === 0;

  if (!isEmpty) {
    inviteeList = invitees.map((invitee) => (
      <Invitee key={invitee._id} {...invitee} />
    ));
  }

  return (
    <Card>
      <InviteeListHeader title={title} invitees={invitees} />
      <Content>{isEmpty ? "No Invitees" : <ul>{inviteeList}</ul>}</Content>
    </Card>
  );
}
