import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import signUpUserWithProvider from "../../auth/signupUserWithProvider";

import { Button } from "react-bulma-components";
import Header from "../Header/Header";
import { Heading } from "react-bulma-components";
import classNames from "./Signin.module.css";

export default function Signup() {
  const { user } = useSelector((state) => state.user);

  function handleSignupWith(type) {
    signUpUserWithProvider(type);
  }

  return user ? (
    <Redirect to="/intro" />
  ) : (
    <>
      <Header />
      <div className={classNames.signin}>
        <Button
          className={`button is-dark has-text-white`}
          color="dark"
          onClick={() => handleSignupWith("google")}
        >
          <span className="icon">
            <i className="fab fa-google"></i>
          </span>
          <span>Sign In with Google</span>
        </Button>
      </div>
      <Heading
        subtitle
        size={5}
        className={`${classNames.signInPrompt} has-text-centered`}
      >
        Sign in with Google to securely manage your events and keep everything tied to your account!
      </Heading>
    </>
  );
}
